var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project-sales-detail"},[_c('div',{staticClass:"container fluid"},[_c('sales-detail',_vm._b({},'sales-detail',{
        isAssigned: _vm.isAssigned,
        downloadAPI: _vm.downloadFile,
        getSalesDetailAPI: _vm.saleAPI.getTrackingData,
        cancelProjectSalesAPI: _vm.saleAPI.cancelProjectSales,
        updateBookingDocumentsAPI: _vm.saleAPI.updateBookingDocuments,
        updateSPADueDateAPI: _vm.saleAPI.updateSPADueDate
      },false))],1)])}
var staticRenderFns = []

export { render, staticRenderFns }